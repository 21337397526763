<template>
  <div class="he-ui-radio-element-container">
    <label class="he-ui-label-radio-element-container">
      <input
        class="he-ui-radio-main"
        type="radio"
        name="locationElementOptions"
        :value="text"
        :id="text"
        :checked="checked"
      />
      <div class="he-ui-location-list-element">
        <div class="d-flex justify-content-between">
          <div>
            <span class='main-text'>{{text}}</span>
          </div>
          <div>
            <img
              v-if="hasChilds"
              :src="iconArrowUnfoldImage"
            />
          </div>
        </div>
        <!-- Suboptions -->
        <div :class="{'he-ui-all-suboptions-container': hasChilds}">
          <div class="he-ui-radio-element-container"
            v-for="(option, index) in childsArray"
            :key="index"
          >
            <label class="he-ui-label-radio-element-container">
              <input
                class="he-ui-radio-suboption"
                type="radio"
                :name="text"
                :id="option.id"
                :value="option.name"
                :checked="index==0"
              />
              <div class="d-flex justify-content-between">
                <div class="suboption-text">{{option.name}}</div>
                <div class="he-ui-custom-radio-checkbox"></div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import iconArrowUnfold from '@/assets/arrow-unfold.svg'
export default {
  props: {
    text: {
      type: String,
      default: null
    },
    childs: {
      type: Array,
      default: null
    },
    checked: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      iconArrowUnfoldImage: iconArrowUnfold,
      childsArray: this.childs
    }
  },

  computed: {
    hasChilds () {
      return this.childsArray?.length
    }
  },

  methods: {
    onClicked () {
      console.log('element clicked')
    }
  }
}
</script>

<style lang="scss" scoped>
  .he-ui-radio-element-container {
    width: 100%;
  }
  .he-ui-label-radio-element-container {
    width: 100%;
  }
  .he-ui-label-radio-element-container > input{ /* HIDE RADIO */
    visibility: hidden; /* Makes input not-clickable */
    position: absolute; /* Remove input from document flow */
  }

  /* Main list of options */
  .he-ui-label-radio-element-container > input.he-ui-radio-main + div{ /* DIV STYLES */
    cursor:pointer;
    border-radius: 14px;
    background: #fff;
    margin: 11px 0px;
    padding: 21px;
    color: #252525;
    font-size: 15px;
    font-weight: 800;
    text-transform: uppercase;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }
  .he-ui-label-radio-element-container > input.he-ui-radio-main:checked + div{ /* (RADIO CHECKED) DIV STYLES */
    background-color: #368AFF;
    color: white;
    font-weight: 800;
    & .he-ui-all-suboptions-container {
      display: block;
      margin-top: 21px;
    }

    & img {
      transform: rotate(90deg);
      filter: invert(1);
    }
  }

  /* Suboptions list */
  .he-ui-all-suboptions-container {
    display: none; /* hidden by default */
  }
  .he-ui-label-radio-element-container > input.he-ui-radio-suboption + div {
    cursor: pointer;
    border-radius: 14px;
    background: #FFF;
    color: #252525;
    font-size: 14px;
    font-weight: 500;
    text-transform: initial;
    padding: 14px;
    margin-bottom: 8px;
    & .he-ui-custom-radio-checkbox {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #f5f5f5;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    }
  }
  .he-ui-label-radio-element-container > input.he-ui-radio-suboption:checked + div {
    & .suboption-text {
      font-weight: 800;
    }
    & .he-ui-custom-radio-checkbox {
      width: 32px;
      height: 32px;
      background: #368AFF;
      border: 8px solid #f5f5f5;
    }
  }
</style>
