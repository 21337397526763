<template>
  <div class="he-ui-no-results-container d-flex flex-column justify-content-center align-items-center">
    <div class="title">
      OOPS!
    </div>
    <div class="normal-text">
      Información no disponible.
    </div>
    <div class="icon">
      <img class="icon" :src="iconQuestionMarkImage">
    </div>
    <div class="normal-text">
      Elige otra fecha.
    </div>
  </div>
</template>

<script>
import iconQuestionMark from '@/assets/question_mark.svg'
export default {
  data () {
    return {
      iconQuestionMarkImage: iconQuestionMark
    }
  }
}
</script>

<style lang="scss" scoped>
  .he-ui-no-results-container {
    border-radius: 11px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    color: #141414;
    padding: 50px 157px;
    // width: 560px;

    & .title {
      font-weight: 700;
      font-size: 45px;
    }

    & .normal-text {
      font-weight: 500;
      font-size: 20px;
    }

    & .icon {
      width: 254px;
      height: 254px;
      border-radius: 50%;
      margin-top: 22px;
      margin-bottom: 63px;
      & img {
        background: rgba(54, 138, 255, 0.08);
      }
    }
  }
</style>
