<template>
  <div class="hide-scroll">
    <div class="he-ui-locations-list-container d-flex flex-column align-items-start justify-content-start">
      <location-list-element
        v-for="(locationTypeElement, index) in getStatisticsLocations"
        :key="index"
        :text="locationTypeElement.name"
        :childs="locationTypeElement.childs"
        :checked="index==0"
        @change="onChangeLocationType(locationTypeElement)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LocationListElement from '@/components/statistics/LocationListEelment.vue'
import fingerprintReader from '@/composition/fingerprintReader'

export default {
  components: {
    LocationListElement
  },

  setup () {
    const { getSerialNumber } = fingerprintReader()

    return {
      getSerialNumber
    }
  },

  computed: {
    ...mapGetters([
      'getStatisticsLocations'
    ])
  },

  methods: {
    ...mapActions([
      'fetchStatisticsLocations',
      'updateLocationFilter',
      'updateLocationTypeFilter'
    ]),
    getStatisticsLocationsList () {
      this.getSerialNumber()
        .then((response) => {
          this.fetchStatisticsLocations(response)
        })
        .catch((e) => {
          console.log('Error capturing sensor serial number')
        })
    },
    onChangeLocationType (locationTypeElement) {
      const optionSelected = document.querySelector(`input[name="${locationTypeElement.name}"]:checked`)?.id
      this.updateLocationTypeFilter(locationTypeElement.locationTypeId)
      this.updateLocationFilter(optionSelected)
    }
  },

  mounted () {
    this.getStatisticsLocationsList()
  }
}
</script>

<style lang="scss" scoped>
  .he-ui-locations-list-container {
    height: 83.8vh;
    width: 332px;
    min-width: 332px;
    border-radius: 14px 14px 0px 0px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
    background: #FFF;
    margin-top: 14px;
    margin-right: 8px;
    padding: 16px 16px 0px 16px;

    position: sticky;
    left: 0;
    z-index: 10;

    overflow-y: auto;
  }
  .hide-scroll {
    overflow: hidden;
    width: 100%;
  }
  // Hide scrollbars keeping scroll feature
  ::-webkit-scrollbar {
    display: none;
  }
</style>
