<template>
  <div class="he-ui-statistics-overview d-flex justify-content-start">
    <div>
      <locations-list />
    </div>

    <div class="w-100 flex-column">
      <calendar-bar />
      <loading-spinner
        v-if="getIsLoadingData"
      />
      <div
        v-else
      >
        <div class="d-flex justify-content-center align-items-center results-container flex-wrap"
            v-if="areResults"
        >
          <results-card
            v-for="(resultCard, index) in getStatisticsData"
            :key="index"
            :title="resultCard.idLocation.name"
            icon="xx"
            dateMode="hoy"
            :date="getSelectedDate"
            :statisticsData="getSortedDataByHours(resultCard.graphData)"
          />
        </div>
        <div
          v-else
        >
          <no-results/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CalendarBar from '@/components/statistics/CalendarBar.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import LocationsList from '@/components/statistics/LocationsList.vue'
import NoResults from '@/components/statistics/NoResults.vue'
import ResultsCard from '@/components/statistics/ResultsCard.vue'
import fingerprintReader from '@/composition/fingerprintReader'

export default {

  setup () {
    const { getSerialNumber } = fingerprintReader()

    return {
      getSerialNumber
    }
  },

  components: {
    CalendarBar,
    LoadingSpinner,
    LocationsList,
    NoResults,
    ResultsCard
  },

  computed: {
    ...mapGetters([
      'getIsLoadingData',
      'getSelectedDate',
      'getFilterDateInit',
      'getFilterDateEnd',
      'getFilterLocation',
      'getFilterLocationType',
      'getStatisticsData'
    ]),
    filterChanges () {
      return [this.getFilterDateInit, this.getFilterDateEnd, this.getFilterLocation, this.getFilterLocationType].join()
    },
    areResults () {
      return Array.from(this.getStatisticsData).length
    }
  },

  watch: {
    filterChanges (value) {
      this.getFilterLocation ? this.getStatistics() : this.getStatisticsByType()
    }
  },

  methods: {
    ...mapActions([
      'fetchStatistics',
      'fetchStatisticsByType'
    ]),
    getStatistics () {
      this.getSerialNumber()
        .then((response) => {
          const params = {
            sensor: response,
            dateInit: this.getFilterDateInit,
            dateEnd: this.getFilterDateEnd,
            location: this.getFilterLocation
          }
          this.fetchStatistics(params)
        })
        .catch((e) => {
          console.log('Error capturing sensor serial number', e)
        })
    },
    getStatisticsByType () {
      this.getSerialNumber()
        .then((response) => {
          const paramsByType = {
            sensor: response,
            dateInit: this.getFilterDateInit,
            dateEnd: this.getFilterDateEnd,
            locationType: this.getFilterLocationType
          }
          this.fetchStatisticsByType(paramsByType)
        })
        .catch((e) => {
          console.log('Error capturing sensor serial number', e)
        })
    },
    getSortedDataByHours (graphData) {
      return Array.from(graphData).sort((a, b) => a.hour - b.hour)
    }
  }
}
</script>
