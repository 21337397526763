<template>
  <div class="he-ui-statistics-bar-component d-flex flex-column align-items-center justify-content-center">
    <div class="he-ui-results-card-bars" :style="cssCalculateBarsHeight">
      <span class="bar-numeric-value">{{ barValue }}</span>
      <div class="bar">
      </div>
      <div class="background-bar" >
      </div>
    </div>
    <div class="time time-start">
      {{ printStartHour }}
    </div>
    <div class="time time-separator">
      -
    </div>
    <div class="time time-end">
      {{ calculateUntilHour }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    maximumValue: {
      type: Number,
      default: 0
    },
    barValue: {
      type: Number,
      default: 0
    },
    hourStart: {
      type: Number,
      default: 0
    }
  },

  computed: {
    printStartHour () {
      // console.log('print start', this.hourStart, typeof this.hourStart)
      return this.formatHour(this.hourStart)
    },
    calculateUntilHour () {
      // Return an hour more
      return this.hourStart < 23 ? this.formatHour(this.hourStart + 1) : this.formatHour(0)
    },
    cssCalculateBarsHeight () {
      const barHeight = 250
      return {
        '--barHeight': barHeight + 'px',
        '--barValue': (this.barValue * barHeight / this.maximumValue) + 'px'
      }
    }
  },

  methods: {
    formatHour (hour) {
      const hours = hour.toString().length === 1 ? `0${hour.toString()}` : hour.toString()
      const minutes = '00'
      return `${hours}:${minutes}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .he-ui-statistics-bar-component {
    margin: 0px 10px;

  }
  .he-ui-results-card-bars {
    position: relative;
    height: 300px;
  }
  .background-bar {
    background: #F0F0F0;
    // background: pink;
    height: var(--barHeight);
    width: 30px;
    border-radius: 20px;
    position: absolute;
    bottom: 0;
    left: -7px;
  }
  .bar {
    margin-top: 33px;
    width: 30px;
    height: var(--barValue);
    background: #368AFF;
    border-radius: 20px;
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: -9px;
  }
  .bar-numeric-value {
    // border: 1px solid red;
  }
  time {
    color: #000;
    font-size: 10px;
    line-height: 8px;
    font-weight: 400;
  }
  .time-start {
    margin-top: 9px;
    font-weight: 700;
  }
  .time-separator {
    line-height: 0;
  }
  .time-end {
    padding-bottom: 10px;
  }
</style>
