<template>
  <div class="he-ui-calendar-bar-container d-flex">
    <div class="he-ui-range-selector-all-container d-flex flex-row">
      <div class=""
        v-for="(rangeOption, index) in getCalendarModes"
        :key="index"
      >
        <label class="he-ui-label-radio-element-container">
          <input
            class="he-ui-radio-option"
            type="radio"
            name="calendarTypes"
            :value="rangeOption.mode"
            :checked="index==0"
            v-model="calendarTypeSelected"
            @change="onChangeMode($event)"
          />
          <div class="he-ui-radio-custom-element">
            {{ $t(rangeOption.text) }}
          </div>
        </label>
      </div>
    </div>

    <div class="he-ui-calendar d-flex justify-content-center align-items-center">
      <datepicker
        v-model="dateSelected"
        iconHeight="0px"
        iconWidth="0px"
        :value="dateSelected"
        :minimum-view="getCalendarModeSelected"
        :maximum-view="getCalendarModeSelected"
        @input="onUpdateDateSelected"
        :language="$t('calendar.language')"
      >
      </datepicker>
    </div>
  </div>
</template>

<script>
import { CALENDAR_MODES } from '@/constants/constants'
import { mapActions, mapGetters } from 'vuex'
import Datepicker from 'vuejs3-datepicker'
import mixins from '@/composition/mixins'

export default {
  setup () {
    const {
      getDayFirstSecond,
      getDayLastSecond,
      getMonthFirstDay,
      getMonthLastDay,
      getYearFirstDay,
      getYearLastDay
    } = mixins()

    return {
      getDayFirstSecond,
      getDayLastSecond,
      getMonthFirstDay,
      getMonthLastDay,
      getYearFirstDay,
      getYearLastDay
    }
  },

  components: {
    Datepicker
  },
  data () {
    return {
      dateSelected: null
    }
  },

  methods: {
    ...mapActions([
      'updateCalendarMode',
      'updateDateFilters'
    ]),
    onUpdateDateSelected () {
      this.updateFilterDates()
    },
    onChangeMode (event) {
      this.updateCalendarMode(event.target.value)
      if (this.calendarTypeSelected === CALENDAR_MODES.MONTH.mode) {
        this.addYearIfMonth()
      }
      this.updateFilterDates()
    },
    addYearIfMonth (removePreviousYear = false) {
      const selector = '.vuejs3-datepicker__content'
      const nodeList = document.querySelectorAll(selector)
      const yearSelected = this.dateSelected ? new Date(this.dateSelected).getFullYear() : ''

      Array.from(nodeList).forEach(
        (i) => {
          setTimeout(() => {
            removePreviousYear
              ? i.innerHTML = `${this.removeYearFromPreviousHTML(i.innerHTML)} ${yearSelected}`
              : i.innerHTML += ` ${yearSelected}`
          }, 10)
        }
      )
    },
    updateFilterDates () {
      const datesFilterArray = []
      switch (this.getCalendarModeSelected) {
        case CALENDAR_MODES.DAY.mode:
          datesFilterArray[0] = this.getDayFirstSecond(this.dateSelected)
          datesFilterArray[1] = this.getDayLastSecond(this.dateSelected)
          break
        case CALENDAR_MODES.MONTH.mode:
          datesFilterArray[0] = this.getMonthFirstDay(this.dateSelected)
          datesFilterArray[1] = this.getMonthLastDay(this.dateSelected)
          break
        case CALENDAR_MODES.YEAR.mode:
          datesFilterArray[0] = this.getYearFirstDay(this.dateSelected)
          datesFilterArray[1] = this.getYearLastDay(this.dateSelected)
          break
        default:
          break
      }
      this.updateDateFilters(datesFilterArray)
    },
    removeYearFromPreviousHTML (dateString) {
      return dateString?.substring(0, dateString.length - 5)
    }
  },

  computed: {
    ...mapGetters([
      'getCalendarModeSelected'
    ]),
    getCalendarModes () {
      return Object.values(CALENDAR_MODES)
    }
  },

  watch: {
    dateSelected (newValue, oldValue) {
      if (this.calendarTypeSelected === CALENDAR_MODES.MONTH.mode) {
        const monthPrevious = oldValue?.getMonth()
        const monthSelected = newValue?.getMonth()
        var removePreviousYearFromHTML = false

        if (monthPrevious === monthSelected) {
          removePreviousYearFromHTML = true
        }

        this.addYearIfMonth(removePreviousYearFromHTML)
      }
    }
  },

  mounted () {
    // Initialize Calendar bar
    const calendarKey = Object.keys(CALENDAR_MODES)[0]
    this.updateCalendarMode(CALENDAR_MODES[calendarKey].mode)
    this.dateSelected = new Date()
    this.updateFilterDates()
  }
}
</script>

<style lang="scss">
  // Center datepicker text
  .vuejs3-datepicker__content {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-left: 0;
  }
</style>

<style lang="scss" scoped>
  .he-ui-calendar-bar-container {
    width: 100%;
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
    padding: 15px;
    margin: 14px 0px;
  }

  .he-ui-calendar {
    width: 100%;
  }

  /* Custom radio button */
  /* Hide default radios */
  .he-ui-label-radio-element-container > input {
    visibility: hidden;
    position: absolute;
  }
  .he-ui-range-selector-all-container {
    display: none;
  }
  /* Customize default option elements */
  .he-ui-label-radio-element-container > input.he-ui-radio-option + div {
    cursor: pointer;
    width: 117px;
    border-radius: 14px;
    background: #F5F5F5;
    color: #252525;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 10px 25px;
    margin: 0px 6px;
  }
  /* Customize active option element */
  .he-ui-label-radio-element-container > input.he-ui-radio-option:checked + div {
    background: #252525;
    color: #FFF;
    font-weight: 800;
  }

</style>
