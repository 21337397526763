<template>
  <modal v-on="$attrs">
    <template #body>
      <div class="he-ui-loading-modal-image mb-4 d-flex flex-column justify-content-between align-items-center">
        <div></div>
        <div class="he-ui-loading-spinner"><div></div><div></div><div></div><div></div></div>
        <div clasS="he-ui-loading-modal-text">{{ $t('main.loading') }}...</div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from './Modal.vue'

export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
.he-ui-loading-modal {
  background-color: #EAEDF1!important;
}
.he-ui-loading-modal-text {
  font-weight: 600;
  font-size: 1.2rem;
}
.he-ui-loading-modal-image {
  width: 350px;
  height: 350px;

  .he-ui-loading-spinner,
  .he-ui-loading-spinner div {
    box-sizing: border-box;
  }
  .he-ui-loading-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .he-ui-loading-spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $primary-color;
    border-radius: 50%;
    animation: he-ui-loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary-color transparent transparent transparent;
  }
  .he-ui-loading-spinner div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .he-ui-loading-spinner div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .he-ui-loading-spinner div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes he-ui-loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
